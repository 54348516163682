import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer className="container">
                <hr/>
                <a href="https://support.novodiagroup.com/hc/en-us">FAQ</a> &nbsp;
                <a href="https://www.mobileebt.com/legal/tos_app_totilpay.html">Legal</a> &nbsp;
                <a href="https://totilpay.com/about-us">About Us</a>
                <p>&copy; 2020-2023 Novo Dia Group</p>
            </footer>
        )
    }
}
